import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import React, { FunctionComponent } from 'react'
import { Video } from '../../shared/media/Video'
import { Card, CardImage } from '../../shared/card/Card'
import { DeleteWrapper } from '../../shared/media/DeleteWrapper'
import { useIntl } from 'react-intl'
import {
  Common_By,
  Common_Delete,
  Moderator_Approve,
  Moderator_Convert_Post_Message,
} from '../../../translations/messages'
import DOMPurify from 'dompurify'
import { isHTML } from '../../../helpers'

const ModeratedCard = styled(Card)`
  margin-bottom: ${spacing.md};
  padding: ${spacing.md};

  &.disabled {
    background: ${colors.black5};
  }
`

const Owner = styled.div`
  margin-bottom: ${spacing.md};
  color: ${colors.joda};
  font-weight: bold;
`

const Creator = styled.div`
  display: inline-block;
  margin-top: ${spacing.sm};
  color: ${colors.joda};
  font-weight: bold;
`

const CardActions = styled.div`
  padding: ${spacing.md} 0 0;
  display: flex;
`

const CardAction = styled.button`
  flex: 1;
  padding: ${spacing.sm} ${spacing.md};
  font-size: inherit;
  height: 3rem;
  color: white;
  border: none;
  border-radius: ${borderRadius};

  &:disabled {
    background: ${colors.black20};
  }
`

const ApproveCardAction = styled(CardAction)`
  margin-right: ${spacing.md};
  background: ${colors.joda};
`

const DisapproveCardAction = styled(CardAction)`
  background: ${colors.error};
`
const HtmlContent = styled.div`
  img {
    max-width: 100%;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }
`

type Props = {
  data: any
  approve: (entityId: string) => void
  disapprove: (entityId: string) => void
  disabled: boolean
  deleteMedia: (id: string, type: 'image' | 'video') => void
  convertToMessage: (entityId: string) => void
}

export const ModeratedListItem: FunctionComponent<Props> = ({
  data,
  disabled,
  disapprove,
  approve,
  deleteMedia,
  convertToMessage,
}) => {
  const intl = useIntl()
  const { entity, owner, media, creator } = data

  return (
    <ModeratedCard className={disabled ? 'disabled' : ''}>
      <Owner>{owner.name}</Owner>

      {entity.type === 'Post' &&
        media.length > 0 &&
        media.map((m: any) =>
          m.mp4_urls ? (
            <DeleteWrapper
              key={m.id}
              onClick={() => deleteMedia(m.id, 'video')}
              disabled={disabled}
            >
              <Video video={m} />
            </DeleteWrapper>
          ) : (
            <DeleteWrapper
              key={m.id}
              onClick={() => deleteMedia(m.id, 'image')}
              disabled={disabled}
            >
              <CardImage src={m.medium} />
            </DeleteWrapper>
          )
        )}

      {entity.text &&
        (isHTML(entity.text) ? (
          <HtmlContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(entity.text),
            }}
          />
        ) : (
          <div>{entity.text}</div>
        ))}

      <div>
        {new Date(entity.created).toLocaleDateString()}{' '}
        {intl.formatMessage(Common_By)}{' '}
        <Creator>
          {creator.first_name} {creator.last_name}
        </Creator>
      </div>

      <CardActions>
        {entity.type === 'Post' && (
          <ApproveCardAction
            onClick={() => convertToMessage(entity.id)}
            disabled={disabled}
          >
            {intl.formatMessage(Moderator_Convert_Post_Message)}
          </ApproveCardAction>
        )}
        <ApproveCardAction
          onClick={() => approve(entity.id)}
          disabled={disabled}
        >
          {intl.formatMessage(Moderator_Approve)}
        </ApproveCardAction>

        <DisapproveCardAction
          onClick={() => disapprove(entity.id)}
          disabled={disabled}
        >
          {intl.formatMessage(Common_Delete)}
        </DisapproveCardAction>
      </CardActions>
    </ModeratedCard>
  )
}
