export const Common_Monday = {
  id: 'Common.Monday',
  defaultMessage: 'Monday',
}

export const Common_Tuesday = {
  id: 'Common.Tuesday',
  defaultMessage: 'Tuesday',
}

export const Common_Wednesday = {
  id: 'Common.Wednesday',
  defaultMessage: 'Wednesday',
}

export const Common_Thursday = {
  id: 'Common.Thursday',
  defaultMessage: 'Thursday',
}

export const Common_Friday = {
  id: 'Common.Friday',
  defaultMessage: 'Friday',
}

export const Common_Saturday = {
  id: 'Common.Saturday',
  defaultMessage: 'Saturday',
}

export const Common_Sunday = {
  id: 'Common.Sunday',
  defaultMessage: 'Sunday',
}

export const Common_Show = {
  id: 'Common.Show',
  defaultMessage: 'Show',
}

export const Common_Hide = {
  id: 'Common.Hide',
  defaultMessage: 'Hide',
}

export const Common_Event = {
  id: 'Common.Event',
  defaultMessage: 'Event',
}

export const Common_Profile = {
  id: 'Common.Profile',
  defaultMessage: 'Profile',
}

export const Common_UserAccount = {
  id: 'Common.UserAccount',
  defaultMessage: 'User account',
}

export const Common_RelationsTo = {
  id: 'Common.RelationsTo',
  defaultMessage: 'Your relations to {name}',
}

export const Common_NumRelations = {
  id: 'Common.NumRelations',
  defaultMessage:
    '{numRelations} {numRelations, plural, one {relation} other {relations}}',
}

export const Common_PermissionsTo = {
  id: 'Common.PermissionsTo',
  defaultMessage: 'Your permissions to {name}',
}

export const Common_NumPermissions = {
  id: 'Common.NumPermissions',
  defaultMessage:
    '{numPermissions} {numPermissions, plural, one {permission} other {permissions}}',
}

export const Common_Book = {
  id: 'Common.Book',
  defaultMessage: 'Book',
}

export const Common_Register = {
  id: 'Common.Register',
  defaultMessage: 'Create an account',
}

export const Common_PublishedBy = {
  id: 'Common.PublishedBy',
  defaultMessage: 'Published by',
}

export const Common_Publish = {
  id: 'Common.Publish',
  defaultMessage: 'Publish',
}

export const Common_Active = {
  id: 'Common.Active',
  defaultMessage: 'Active',
}

export const Common_Inactive = {
  id: 'Common.Inactive',
  defaultMessage: 'Inactive',
}

export const Common_Activate = {
  id: 'Common.Activate',
  defaultMessage: 'Activate',
}

export const Common_DataController = {
  id: 'Common.DataController',
  defaultMessage: 'Data controller',
}

export const Common_NoDataController = {
  id: 'Common.NoDataController',
  defaultMessage: 'Not set',
}

export const Common_Accept = {
  id: 'Common.Accept',
  defaultMessage: 'Accept',
}

export const Common_Deactivate = {
  id: 'Common.Deactivate',
  defaultMessage: 'Deactivate',
}

export const Common_New = {
  id: 'Common.New',
  defaultMessage: 'New',
}

export const Common_Teams = {
  id: 'Common.Teams',
  defaultMessage: 'Teams',
}

export const Common_CountryCode = {
  id: 'Common.CountryCode',
  defaultMessage: 'Country code',
}

export const Common_VideoCalls = {
  id: 'Common.VideoCalls',
  defaultMessage: 'Video calls',
}

export const Common_ErrorOccurred = {
  id: 'Common.ErrorOccurred',
  defaultMessage: 'An error occurred. Please try again.',
}

export const Common_TryAgain = {
  id: 'Common.TryAgain',
  defaultMessage: 'Try again',
}

export const Common_Greeting = {
  id: 'Common.Greeting',
  defaultMessage: 'Hi, {name}!',
}

export const Common_AnythingToShare = {
  id: 'Common.AnythingToShare',
  defaultMessage: 'Only share nice stories and pictures!',
}

export const Common_PleaseWait = {
  id: 'Common.PleaseWait',
  defaultMessage: 'Please wait...',
}

export const Common_NoResults = {
  id: 'Common.NoResults',
  defaultMessage: 'No results',
}

export const Common_SelectedUsers = {
  id: 'Common.SelectedUsers',
  defaultMessage: 'Selected users',
}

export const Common_Add = {
  id: 'Common.Add',
  defaultMessage: 'Add',
}

export const Common_Member = {
  id: 'Common.Member',
  defaultMessage: 'Member',
}

export const Common_EmployedAt = {
  id: 'Common.EmployedAt',
  defaultMessage: 'Employee',
}

export const Common_Admin = {
  id: 'Common.Admin',
  defaultMessage: 'Admin',
}

export const Common_Remove = {
  id: 'Common.Remove',
  defaultMessage: 'Remove',
}

export const Common_Confirm = {
  id: 'Common.Confirm',
  defaultMessage: 'Confirm',
}

export const Common_Title = {
  id: 'Common.Title',
  defaultMessage: 'Title',
}

export const Common_Description = {
  id: 'Common.Description',
  defaultMessage: 'Description',
}

export const Common_CellPhoneNumber = {
  id: 'Common.CellPhoneNumber',
  defaultMessage: 'Cell phone number',
}

export const Common_Text = {
  id: 'Common.Text',
  defaultMessage: 'Text',
}

export const Common_Save = {
  id: 'Common.Save',
  defaultMessage: 'Save',
}

export const Common_FirstName = {
  id: 'Common.FirstName',
  defaultMessage: 'First name',
}

export const Common_LastName = {
  id: 'Common.LastName',
  defaultMessage: 'Last name',
}

export const Common_Email = {
  id: 'Common.Email',
  defaultMessage: 'E-mail',
}

export const Common_Password = {
  id: 'Common.Password',
  defaultMessage: 'Password',
}

export const Common_About = {
  id: 'Common.About',
  defaultMessage: 'About',
}

export const Common_Edit = {
  id: 'Common.Edit',
  defaultMessage: 'Edit',
}

export const Common_Delete = {
  id: 'Common.Delete',
  defaultMessage: 'Delete',
}

export const Common_Info = {
  id: 'Common.Info',
  defaultMessage: 'Info',
}

export const Common_Calendar = {
  id: 'Common.Calendar',
  defaultMessage: 'Calendar',
}

export const Common_Messages = {
  id: 'Common.Messages',
  defaultMessage: 'Messages',
}

export const Common_Relations = {
  id: 'Common.Relations',
  defaultMessage: 'Relations',
}

export const Common_Members = {
  id: 'Common.Members',
  defaultMessage: 'Members',
}

export const Common_ServiceReceiver = {
  id: 'Common.ServiceReceiver',
  defaultMessage: 'Service User',
}

export const Common_ServiceReceivers = {
  id: 'Common.ServiceReceivers',
  defaultMessage: 'Service Users',
}

export const Common_Groups = {
  id: 'Common.Groups',
  defaultMessage: 'Groups',
}

export const Common_Edit_Group = {
  id: 'Common.Edit.Group',
  defaultMessage: 'Edit Group',
}
export const Common_MyInstitutions = {
  id: 'Common.MyInstitutions',
  defaultMessage: 'My institutions',
}

export const Common_More = {
  id: 'Common.More',
  defaultMessage: 'More',
}

export const Common_Logout = {
  id: 'Common.Logout',
  defaultMessage: 'Log out',
}

export const Common_By = {
  id: 'Common.By',
  defaultMessage: 'by',
}

export const Common_Cancel = {
  id: 'Common.Cancel',
  defaultMessage: 'Cancel',
}

export const Common_Yes = {
  id: 'Common.Yes',
  defaultMessage: 'Yes',
}

export const Common_No = {
  id: 'Common.No',
  defaultMessage: 'No',
}

export const Common_Heart = {
  id: 'Common.Heart',
  defaultMessage: 'Heart',
}

export const Common_At = {
  id: 'Common.At',
  defaultMessage: 'At',
}

export const Common_Comment = {
  id: 'Common.Comment',
  defaultMessage: 'Comment',
}

export const Comment_WriteAComment = {
  id: 'Common.WriteAComment',
  defaultMessage: 'Write a comment',
}

export const Common_ChooseBook = {
  id: 'Common.ChooseBook',
  defaultMessage: 'Choose book',
}

export const Common_ChooseServiceUser = {
  id: 'Common_ChooseServiceUser',
  defaultMessage: 'Choose Service User',
}

export const Common_Ok = {
  id: 'Common.Ok',
  defaultMessage: 'Ok',
}

export const Common_AddMedia = {
  id: 'Common.AddMedia',
  defaultMessage: 'Add image/video',
}

export const Common_ReadMore = {
  id: 'Common.ReadMore',
  defaultMessage: 'Read more',
}

export const Common_ModeratedContent = {
  id: 'Common.ModeratedContent',
  defaultMessage:
    'This post may contain sensitive information and must be approved by the moderator to be displayed.',
}
export const Common_AppNote = {
  id: 'Common.AppNote',
  defaultMessage:
    'Hei! Kan du si kort hvordan Jodacare hjelper deg å sikre god informasjon og trygghet rundt brukeren? Send svaret på e-post – tusen takk! 🙏',
}
export const Common_NumComments = {
  id: 'Common.NumComments',
  defaultMessage:
    '{numComments} {numComments, plural, one {comment} other {comments}}',
}

export const Common_NumMembers = {
  id: 'Common.NumMembers',
  defaultMessage:
    '{numMembers} {numMembers, plural, one {member} other {members}}',
}

export const Common_Notify = {
  id: 'Common.Notify',
  defaultMessage: 'Notify',
}

export const Common_Moderator = {
  id: 'Common.Moderator',
  defaultMessage: 'Moderator',
}

export const Common_Institutions = {
  id: 'Common.Institutions',
  defaultMessage: 'Institutions',
}

export const Common_Today = {
  id: 'Common.Today',
  defaultMessage: 'Today',
}

export const Common_Prev = {
  id: 'Common.Prev',
  defaultMessage: 'Prev',
}

export const Common_Next = {
  id: 'Common.Next',
  defaultMessage: 'Next',
}

export const Common_Close = {
  id: 'Common.Close',
  defaultMessage: 'Close',
}

export const Common_Name = {
  id: 'Common.Name',
  defaultMessage: 'Name',
}

export const Common_Search = {
  id: 'Common.Search',
  defaultMessage: 'Search',
}

export const Common_LoadMore = {
  id: 'Common.LoadMore',
  defaultMessage: 'Load more',
}

export const Moderator_EmptyStateTitle = {
  id: 'Moderator.EmptyStateTitle',
  defaultMessage: 'Nothing here',
}

export const Moderator_Set = {
  id: 'Moderator.Set',
  defaultMessage: 'Set moderator',
}

export const Moderator_NotSet = {
  id: 'Moderator.NotSet',
  defaultMessage: 'Not set',
}

export const Moderator_Approve = {
  id: 'Moderator.Approve',
  defaultMessage: 'Approve',
}
export const Moderator_Convert_Post_Message = {
  id: 'Moderator.Convert.PostMessage',
  defaultMessage: 'Message',
}
export const Moderator_EmptyStateDescription = {
  id: 'Moderator.EmptyStateDescription',
  defaultMessage: 'Grab a coffee',
}

export const Moderator_NotifyTitle = {
  id: 'Moderator.NotifyTitle',
  defaultMessage: 'Do you want to notify the moderator about this?',
}

export const Moderator_Delete = {
  id: 'Moderator.Delete',
  defaultMessage: 'Do you want to delete this?',
}

export const Moderator_ConfirmApprove = {
  id: 'Moderator.ConfirmApprove',
  defaultMessage: 'Do you want to approve this?',
}
export const Moderator_ConfirmConvertingToMessage = {
  id: 'Moderator.ConfirmConvertingToMessage',
  defaultMessage:
    'This action will delete the post and convert it to message. Do you allow this?',
}

export const Moderator_ConfirmDelete = {
  id: 'Moderator.ConfirmDelete',
  defaultMessage: 'Do you want to delete this?',
}

export const Moderator_Update = {
  id: 'Moderator.Update',
  defaultMessage: 'Update moderator?',
}

export const Messages_Message = {
  id: 'Messages.Message',
  defaultMessage: 'Message',
}

export const Messages_MarkAsRead = {
  id: 'Messages.MarkAsRead',
  defaultMessage: 'Mark message as read?',
}

export const Messages_Important = {
  id: 'Messages.Important',
  defaultMessage: 'Important!',
}

export const Messages_AccessWarning = {
  id: 'Messages.AccessWarning',
  defaultMessage: 'Messages are visible to closest relative and employees',
}

export const Messages_HighPriority = {
  id: 'Messages.HighPriority',
  defaultMessage: 'High priority',
}

export const Messages_New = {
  id: 'Messages.New',
  defaultMessage: 'New!',
}

export const Messages_Create = {
  id: 'Messages.Create',
  defaultMessage: 'New message',
}

export const Messages_ConfirmDelete = {
  id: 'Messages.ConfirmDelete',
  defaultMessage: 'Delete message?',
}

export const Messages_EmptyStateTitle = {
  id: 'Messages.EmptyStateTitle',
  defaultMessage: 'No messages',
}

export const Messages_MarkAllAsRead = {
  id: 'Messages.MarkAllAsRead',
  defaultMessage: 'Mark all as read',
}

export const Messages_EmptyStateDescription = {
  id: 'Messages.EmptyStateDescription',
  defaultMessage: 'No messages published yet',
}

export const Messages_WriteAMessage = {
  id: 'Messages.WriteAMessage',
  defaultMessage: 'Share a message to closest relatives and employees',
}

export const Posts_EmptyStateTitle = {
  id: 'Posts.EmptyStateTitle',
  defaultMessage: 'No posts',
}

export const Posts_EmptyStateDescription = {
  id: 'Posts.EmptyStateDescription',
  defaultMessage: 'No posts published yet',
}

export const Posts_Delete = {
  id: 'Post.Delete',
  defaultMessage: 'Delete post?',
}

export const Post_Edit = {
  id: 'Post.Edit',
  defaultMessage: 'Edit Post',
}

export const Jodabook_ConfirmUpdate = {
  id: 'Jodabook.ConfirmUpdate',
  defaultMessage: 'Update Jodabook settings?',
}

export const Jodabook_TurnOff = {
  id: 'Jodabook.TurnOff',
  defaultMessage: 'Turn off Jodabook?',
}

export const Comment_ConfirmDelete = {
  id: 'Comment.ConfirmDelete',
  defaultMessage: 'Delete comment?',
}

export const Invitations_ConfirmDelete = {
  id: 'Invitations.ConfirmDelete',
  defaultMessage: 'Delete invitation?',
}

export const Invitations_Invitations = {
  id: 'Invitations.Invitations',
  defaultMessage: 'Invitations',
}

export const Invitations_InviteUsers = {
  id: 'Invitations.InviteUsers',
  defaultMessage: 'Invite users',
}

export const Invitations_NoInvitations = {
  id: 'Invitations.NoInvitations',
  defaultMessage: 'No invitations',
}

export const Invitations_SendInvitations = {
  id: 'Invitations.SendInvitations',
  defaultMessage:
    'Send {numInvitations} {numInvitations, plural, one {invitation} other {invitations}}',
}

export const Relations_ConfirmRemove = {
  id: 'Relations.ConfirmRemove',
  defaultMessage: 'Remove user relation?',
}

export const Relations_Relation = {
  id: 'Relations.Relation',
  defaultMessage: 'Relation',
}

export const Relations_ChooseRelation = {
  id: 'Relations.ChooseRelation',
  defaultMessage: 'Choose relation',
}

export const Relations_AddRelationType = {
  id: 'Relations.AddRelationType',
  defaultMessage: 'Add users',
}

export const Event_Create = {
  id: 'Event.Create',
  defaultMessage: 'Create event',
}

export const Event_Cancel = {
  id: 'Event.Cancel',
  defaultMessage: 'Cancel event',
}

export const Event_UnCancel = {
  id: 'Event.UnCancel',
  defaultMessage: 'Uncancel event',
}

export const Event_Cancelled = {
  id: 'Event.Cancelled',
  defaultMessage: 'This event has been cancelled',
}

export const Event_AllDay = {
  id: 'Event.AllDay',
  defaultMessage: 'All day',
}

export const Event_DoesNotRepeat = {
  id: 'Event.DoesNotRepeat',
  defaultMessage: 'Does not repeat',
}

export const Event_Repeats = {
  id: 'Event.Repeats',
  defaultMessage: 'Repeats',
}

export const Event_RepeatDaily = {
  id: 'Event.RepeatDaily',
  defaultMessage: 'Repeats daily',
}

export const Event_RepeatWeekly = {
  id: 'Event.RepeatWeekly',
  defaultMessage: 'Repeats weekly',
}

export const Event_RepeatMonthly = {
  id: 'Event.RepeatMonthly',
  defaultMessage: 'Repeats monthly',
}

export const Event_RepeatYearly = {
  id: 'Event.RepeatYearly',
  defaultMessage: 'Repeats yearly',
}

export const Event_RepeatXDaily = {
  id: 'Event.RepeatXDaily',
  defaultMessage:
    'Repeat every {interval} {interval, plural, one {day} other {days}}',
}

export const Event_Edit = {
  id: 'Event.Edit',
  defaultMessage: 'Edit event',
}

export const Event_RepeatXWeekly = {
  id: 'Event.RepeatXWeekly',
  defaultMessage:
    'Repeat every {interval} {interval, plural, one {week} other {weeks}}',
}

export const Event_RepeatXMonthly = {
  id: 'Event.RepeatXMonthly',
  defaultMessage:
    'Repeat every {interval} {interval, plural, one {month} other {months}}',
}

export const Event_RepeatXYearly = {
  id: 'Event.RepeatXYearly',
  defaultMessage:
    'Repeat every {interval} {interval, plural, one {year} other {years}}',
}

export const Event_StartMustBeBeforeEndError = {
  id: 'Event.StartMustBeBeforeEndError',
  defaultMessage: 'Start must be before end',
}

export const Event_Start = {
  id: 'Event.Start',
  defaultMessage: 'Start',
}

export const Event_End = {
  id: 'Event.End',
  defaultMessage: 'End',
}

export const Event_EndRecurringDate = {
  id: 'Event.EndRecurringDate',
  defaultMessage: 'Repeat until',
}

export const Event_ConfirmCancelOccurrence = {
  id: 'Event.ConfirmCancelOccurrence',
  defaultMessage: 'Do you want to cancel this single occurrence?',
}

export const Event_ConfirmUnCancelOccurrence = {
  id: 'Event.ConfirmUnCancelOccurrence',
  defaultMessage: 'Do you want to uncancel this single occurrence?',
}

export const Event_ConfirmDeleteEvent = {
  id: 'Event.ConfirmDeleteEvent',
  defaultMessage: 'Do you want to delete this event?',
}

export const Event_Image = {
  id: 'Event.Image',
  defaultMessage: 'Add Image',
}

export const Users_NoUsers = {
  id: 'Users.NoUsers',
  defaultMessage: 'No users',
}

export const Users_Title = {
  id: 'Users.Title',
  defaultMessage: 'Users',
}

export const Users_SearchPlaceholder = {
  id: 'Users.SearchPlaceholder',
  defaultMessage: 'Search users',
}

export const Login_Login = {
  id: 'Login.Login',
  defaultMessage: 'Log in',
}

export const Login_SelectAuthMethod = {
  id: 'Login.SelectAuthMethod',
  defaultMessage: 'Select authentication method',
}

export const Login_ChangeAuthMethod = {
  id: 'Login.ChangeAuthMethod',
  defaultMessage: 'Change authentication method',
}

export const Common_ContactUs = {
  id: 'Common.ContactUs',
  defaultMessage: 'Contact us',
}

export const Common_Or = {
  id: 'Common.Or',
  defaultMessage: 'or',
}

export const ContactUs_Description = {
  id: 'ContactUs.Description',
  defaultMessage:
    'Great that you want to use Jodacare at your workplace! To get started we need some more information.',
}

export const ContactUs_SendEmailTo = {
  id: 'ContactUs.SendEmailTo',
  defaultMessage: 'Send e-email to {email}',
}

export const ContactUs_CallUsAt = {
  id: 'ContactUs.CallUsAt',
  defaultMessage: 'call +47{phone}',
}

export const Login_SearchMunicipality = {
  id: 'Login.SearchMunicipality',
  defaultMessage: 'Search municipality',
}

export const Login_ChangeMunicipality = {
  id: 'Login.ChangeMunicipality',
  defaultMessage: 'Change municipality',
}

export const Login_TokenExpired = {
  id: 'Login.TokenExpired',
  defaultMessage: 'Session has expired. Please log in again.',
}

export const VerifyAccountPage_SMSCode = {
  id: 'VerifyAccountPage.SMSCode',
  defaultMessage: 'SMS code',
}

export const VerifyAccountPage_NoAccount = {
  id: 'VerifyAccountPage.NoAccount',
  defaultMessage:
    'Are you sure that you have a Jodacare account connected to this phone number?',
}

export const VerifyAccountPage_CreateAccountLink = {
  id: 'VerifyAccountPage.CreateAccountLink',
  defaultMessage: 'You can create an account here',
}

export const VerifyAccountPage_AccountConfirmedTitle = {
  id: 'VerifyAccountPage.AccountConfirmedTitle',
  defaultMessage: 'Account confirmed!',
}

export const VerifyAccountPage_AccountConfirmedText = {
  id: 'VerifyAccountPage.AccountConfirmedText',
  defaultMessage: 'Du can now start using Jodacare',
}

export const VerifyAccountPage_StartUsingJodacare = {
  id: 'VerifyAccountPage.StartUsingJodacare',
  defaultMessage: 'Start using Jodacare',
}

export const VerifyAccountPage_ConfirmAccountTitle = {
  id: 'VerifyAccountPage.ConfirmAccountTitle',
  defaultMessage: 'Confirm account',
}

export const VerifyAccountPage_ConfirmAccountText = {
  id: 'VerifyAccountPage.ConfirmAccountText',
  defaultMessage: 'We have to confirm that you are the owner of the account.',
}

export const VerifyAccountPage_SMSCodeText = {
  id: 'VerifyAccountPage.SMSCodeText',
  defaultMessage:
    'We have sent you a code to verify that you are the owner of this account.',
}

export const VerifyAccountPage_ReceiveCode = {
  id: 'VerifyAccountPage.ReceiveCode',
  defaultMessage: 'Receive code',
}

export const Profile_Edit = {
  id: 'Profile.Edit',
  defaultMessage: 'Edit profile',
}

export const Profile_MyProfile = {
  id: 'Profile.MyProfile',
  defaultMessage: 'My profile',
}

export const Settings_Settings = {
  id: 'Settings.Settings',
  defaultMessage: 'Settings',
}

export const Settings_AuthExpire = {
  id: 'Settings.AuthExpire',
  defaultMessage: 'Auth expiration',
}

export const Settings_Notifications = {
  id: 'Settings.Notifications',
  defaultMessage: 'Notifications',
}

export const Settings_NotifyCancelEvents = {
  id: 'Settings.NotifyCancelEvents',
  defaultMessage: 'Cancelled events',
}

export const Settings_NotifyNewEvents = {
  id: 'Settings.NotifyNewEvents',
  defaultMessage: 'New events',
}

export const Settings_NotifyNewComments = {
  id: 'Settings.NotifyNewComments',
  defaultMessage: 'New comments',
}

export const Settings_NotifyNewMessages = {
  id: 'Settings.NotifyNewMessages',
  defaultMessage: 'New messages',
}

export const Settings_NotifyNewPosts = {
  id: 'Settings.NotifyNewPosts',
  defaultMessage: 'New posts',
}

export const Settings_NotifyNewReactions = {
  id: 'Settings.NotifyNewReactions',
  defaultMessage: 'New reactions',
}

export const Settings_Language = {
  id: 'Settings.Language',
  defaultMessage: 'Language',
}

export const Settings_Hour = {
  id: 'Settings.Hour',
  defaultMessage:
    'Auth expires after {hours} {hours, plural, one {hour} other {hours}}',
}

export const Settings_Week = {
  id: 'Settings.Week',
  defaultMessage:
    'Auth expires after {weeks} {weeks, plural, one {week} other {weeks}}',
}

export const Settings_Account = {
  id: 'Settings.Account',
  defaultMessage: 'Account',
}

export const Language_Norwegian = {
  id: 'Language.Norwegian',
  defaultMessage: 'Norsk',
}

export const Language_English = {
  id: 'Language.English',
  defaultMessage: 'English',
}

export const EULA_Terms = {
  id: 'EULA.Terms',
  defaultMessage: 'Terms',
}

export const Institution_Employees = {
  id: 'Institutions.Employees',
  defaultMessage: 'Employees',
}

export const Institutions_ChildOf = {
  id: 'Institutions.ChildOf',
  defaultMessage: 'Child of',
}

export const Institutions_Departments = {
  id: 'Institutions.Departments',
  defaultMessage: 'Departments',
}

export const Common_MyNetwork = {
  id: 'Common.MyNetwork',
  defaultMessage: 'My relations',
}

export const More_AllGroups = {
  id: 'More.AllGroups',
  defaultMessage: 'All groups',
}

export const More_AllTeams = {
  id: 'More.AllTeams',
  defaultMessage: 'All teams',
}
export const Edit_Team = {
  id: 'Edit.Team',
  defaultMessage: 'Edit Team',
}

export const More_AllInstitutions = {
  id: 'More.AllInstitutions',
  defaultMessage: 'All institutions',
}

export const More_Support = {
  id: 'More.Support',
  defaultMessage: 'Support and feedback',
}

export const More_ReportBug = {
  id: 'More.ReportBug',
  defaultMessage: 'Report an error',
}

export const More_Survey = {
  id: 'More.Survey',
  defaultMessage: 'brukerundersøkelse',
}
export const Common_NoAccessTitle = {
  id: 'VideoCalls.NoAccessTitle',
  defaultMessage: 'No access',
}

export const Common_NoAccessText = {
  id: 'VideoCalls.NoAccessText',
  defaultMessage: 'Contact the administrator if you think this is not correct.',
}

export const Cropper_SelectImage = {
  id: 'Cropper.SelectImage',
  defaultMessage: 'Select image',
}

export const Permission_Read = {
  id: 'Permission.Read',
  defaultMessage: 'Read',
}

export const Permission_Create = {
  id: 'Permission.Create',
  defaultMessage: 'Create',
}

export const Permission_Update = {
  id: 'Permission.Update',
  defaultMessage: 'Edit',
}

export const Permission_Delete = {
  id: 'Permission.Delete',
  defaultMessage: 'Delete',
}

export const Relation_ClosestRelative = {
  id: 'Relation.ClosestRelative',
  defaultMessage: 'Closest relative',
}

export const Relation_Moderator = {
  id: 'Relation.Moderator',
  defaultMessage: 'Moderator',
}

export const Relation_TeamMember = {
  id: 'Relation.TeamMember',
  defaultMessage: 'Team member',
}

export const Relation_ManagerMember = {
  id: 'Relation.ManagerMember',
  defaultMessage: 'Manager',
}

export const Relation_EmployedAtMember = {
  id: 'Relation.EmployedAtMember',
  defaultMessage: 'Employed at',
}

export const Relation_Created = {
  id: 'Relation.Created',
  defaultMessage: 'Created',
}

export const Relation_PrimaryContact = {
  id: 'Relation.PrimaryContact',
  defaultMessage: 'Primary contact',
}

export const Relation_Family = {
  id: 'Relation.Family',
  defaultMessage: 'Family',
}

export const Relation_MemberEmployedAt = {
  id: 'Relation.MemberEmployedAt',
  defaultMessage: 'Employed at',
}

export const Relation_MemberManager = {
  id: 'Relation.MemberManager',
  defaultMessage: 'Manager',
}

export const Relation_Employee = {
  id: 'Relation.Employee',
  defaultMessage: 'Employee',
}

export const Relation_Manager = {
  id: 'Relation.Manager',
  defaultMessage: 'Manager',
}

export const Relation_Invited = {
  id: 'Relation.Invited',
  defaultMessage: 'Invited',
}

export const Relation_ClosestRelativeWhereManager = {
  id: 'Relation.ClosestRelativeWhereManager',
  defaultMessage: 'Closest relative at {name} where you are manager',
}

export const Relation_ClosestRelativeWherePrimaryContact = {
  id: 'Relation.ClosestRelativeWherePrimaryContact',
  defaultMessage: 'Closest relative to {name} where you are primary contact',
}

export const Relation_ClosestRelativeWhereEmployed = {
  id: 'Relation.ClosestRelativeWhereEmployed',
  defaultMessage: 'Closest relative at {name} where you are employed',
}

export const Relation_ManagerEmployedAt = {
  id: 'Relation.ManagerEmployedAt',
  defaultMessage: 'Manager',
}

export const Relation_EmployedAtEmployedAt = {
  id: 'Relation.EmployedAtEmployedAt',
  defaultMessage: 'Employed at',
}

export const Relation_ManagerMemberFamily = {
  id: 'Relation.ManagerMemberFamily',
  defaultMessage: 'Manager where family',
}

export const Relation_EmployedAtMemberFamily = {
  id: 'Relation.EmployedAtMemberFamily',
  defaultMessage: 'Employed at where family',
}

export const Relation_EmployedAtMemberPrimaryContact = {
  id: 'Relation.EmployedAtMemberPrimaryContact',
  defaultMessage: 'Employed at where primary contact',
}

export const Relation_You = {
  id: 'Relation.You',
  defaultMessage: 'Yourself',
}

export const Relation_DataControllerWhereMember = {
  id: 'Relation.DataControllerWhereMember',
  defaultMessage: 'Data Controller',
}

export const Relation_ManagerAtParent = {
  id: 'Relation.ManagerAtParent',
  defaultMessage: 'Manager at parent institution',
}

export const Relation_EmployedAtParent = {
  id: 'Relation.EmployedAtParent',
  defaultMessage: 'Employed at parent institution',
}

export const Relation_DataController = {
  id: 'Relation.DataController',
  defaultMessage: 'Data controller',
}

export const Register_Title = {
  id: 'Register.Title',
  defaultMessage: 'Welcome to Jodacare!',
}

export const Register_Description = {
  id: 'Register.Description',
  defaultMessage:
    'In order for us to give you access, we need some information about you. The information will only be used to create a Jodacare profile, will be handled securely and will not be distributed to third parties.',
}

export const Register_SelectRoleTitle = {
  id: 'Register.SelectRoleTitle',
  defaultMessage: 'First, what is your role?',
}

export const Register_ManagerRole = {
  id: 'Register.ManagerRole',
  defaultMessage: 'Manager at an institution',
}

export const Register_BPARole = {
  id: 'Register.BPA',
  defaultMessage:
    'BPA or an administrator in a family or other type of administrator role associated with one person.',
}

export const Register_RelativeRole = {
  id: 'Register.RelativeRole',
  defaultMessage:
    'Relative, spouse, child, grandchild, friend, or others in connection with or in relation to the recipient of health care services.',
}

export const Register_EmployeeRole = {
  id: 'Register.EmployeeRole',
  defaultMessage:
    'Employees at a institution, school, day care center, home service or similar.',
}

export const Register_CreateAccountTitle = {
  id: 'Register.CreateAccountTitle',
  defaultMessage: 'Now we would like to get to know you better!',
}

export const Register_WithCountryCode = {
  id: 'Register.WithCountryCode',
  defaultMessage: 'With country code. Ex. +47',
}

export const Register_VerifyTitle = {
  id: 'Register.VerifyTitle',
  defaultMessage: 'Verify',
}

export const Register_VerifyDescription = {
  id: 'Register.VerifyDescription',
  defaultMessage:
    'For security reasons and processing of sensitive personal information, we refine you through ID-porten. We cannot access any information other than confirmation that you are who you are. When you verify, you also agree to our data processing agreement.',
}

export const Register_EmailInUse = {
  id: 'Register.EmailInUse',
  defaultMessage: 'E-mail already in use',
}

export const Register_PhoneNumberInUse = {
  id: 'Register.PhoneNumberInUse',
  defaultMessage: 'Phone number already in use',
}

export const EULA_Greeting = {
  id: 'EULA.Greeting',
  defaultMessage: 'Hi {name}',
}

export const EULA_AcceptCrm = {
  id: 'EULA.AcceptCrm',
  defaultMessage: 'My data is saved to Jodacare customer management system',
}

export const EULA_AcceptJodacare = {
  id: 'EULA.AcceptJodacare',
  defaultMessage:
    'My data is saved to Jodacare (you will not be able to use Jodacare without this)',
}

export const ServiceReceivers_Create = {
  id: 'ServiceReceivers.Create',
  defaultMessage: 'Create New Service User',
}

export const CreateServiceReceiver_HaveConsent = {
  id: 'CreateServiceReceivers.HaveConsent',
  defaultMessage: 'I have consent',
}

export const CreateServiceReceiver_ConsentDescription = {
  id: 'CreateServiceReceivers.ConsentDescription',
  defaultMessage:
    'To create an account for a service receiver you need consent from the service receiver or the closest relative',
}

export const CreateServiceReceiver_UsingJodabook = {
  id: 'CreateServiceReceivers.UsingJodabook',
  defaultMessage: 'Using Jodabook',
}

export const Groups_Create = {
  id: 'Groups.Create',
  defaultMessage: 'Create Group',
}

export const Groups_EmptyState = {
  id: 'Groups.EmptyState',
  defaultMessage: 'No groups',
}

export const Groups_MessagesDeprecatedNotice = {
  id: 'Groups.MessagesDeprecatedNotice',
  defaultMessage:
    'Group messages have now been deprecated, but you can still view old messages under on the Messages page',
}

export const Institutions_EmptyState = {
  id: 'Institutions.EmptyState',
  defaultMessage: 'No institutions',
}

export const Institutions_NoManager = {
  id: 'Institutions.NoManager',
  defaultMessage: 'No manager',
}

export const Institution_NumDepartments = {
  id: 'Institutions.NumDepartments',
  defaultMessage:
    '{numDepartments} {numDepartments, plural, one {department} other {departments}}',
}

export const Teams_Create = {
  id: 'Teams.Create',
  defaultMessage: 'Create Team',
}

export const MyInstitutions_ShowList = {
  id: 'MyInstitutions.ShowList',
  defaultMessage: 'Show list',
}

export const MyInstitutions_ShowHierarchy = {
  id: 'MyInstitutions.ShowHierarchy',
  defaultMessage: 'Show hierarchy',
}

export const VideoCall_InitiateCall = {
  id: 'VideoCall.InitiateCall',
  defaultMessage: 'Call {name}',
}

export const VideoCall_AcceptCall = {
  id: 'VideoCall.AcceptCall',
  defaultMessage: 'Accept',
}

export const VideoCall_DeclineCall = {
  id: 'VideoCall.DeclineCall',
  defaultMessage: 'Decline',
}

export const VideoCall_CurrentCall = {
  id: 'VideoCall.CurrentCall',
  defaultMessage: 'Videocall with {name}',
}

export const VideoCall_IsCalling = {
  id: 'VideoCall.IsCalling',
  defaultMessage: '{name} is calling!',
}

export const VideoCall_Calling = {
  id: 'VideoCall.Calling',
  defaultMessage: 'Calling {name}',
}

export const VideoCall_InitiateCallConfirm = {
  id: 'VideoCall.InitiateCallConfirm',
  defaultMessage: 'Do you want to call {name}?',
}

export const Relations_EmptyState = {
  id: 'Relations.EmptyState',
  defaultMessage: 'No relations',
}

export const Teams_EmptyState = {
  id: 'Teams.EmptyState',
  defaultMessage: 'No teams',
}
export const Video_Status_Ingest = {
  id: 'Video.Status.Ingest',
  defaultMessage: 'The video is being processed',
}
export const Video_Status_Unknown = {
  id: 'Video.Status.Unknown',
  defaultMessage: 'Waiting for processing',
}
export const Video_Status_Error = {
  id: 'Video.Status.Error',
  defaultMessage: 'Cannot process the video',
}
export const Create_Account = {
  id: 'Login.CreateAccount',
  defaultMessage: 'New user? Create your profile here',
}
export const Common_ReadLess = {
  id: 'Common.ReadLess',
  defaultMessage: 'Read less',
}
export const Group_DeleteConfirmation = {
  id: 'Group.DeleteConfirmation',
  defaultMessage: 'Do you want to delete group {name}?',
}
