import React, { FunctionComponent, useContext, useState } from 'react'
import {
  Card,
  CardHeader,
  CardAction,
  CardActions,
  CardMeta,
  Meta,
  CardTextWithLoadMore,
} from '../card/Card'
import { colors, spacing } from '../../../styles/styles'
import styled from 'styled-components'
import { ModeratedContendCard } from '../ModeratedContendCard'
import { Avatar } from '../Avatar'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../../contexts/AuthContext'
import { usePosts } from '../../../contexts/PostProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faHeart } from '@fortawesome/free-solid-svg-icons'
import { ConfirmDialog } from '../modal/ConfirmDialog'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { SwipeableMedia } from './SwipeableMedia'
import { CardMenu } from '../card/CardMenu'
import { NotifyButton } from '../NotifyButton'
import { useIntl } from 'react-intl'
import {
  Common_By,
  Common_Cancel,
  Common_Comment,
  Common_Delete,
  Common_Heart,
  Common_NumComments,
  Common_ReadLess,
  Common_ReadMore,
  Posts_Delete,
} from '../../../translations/messages'
import { Button } from '../Buttons'
import 'react-quill/dist/quill.snow.css'
import DOMPurify from 'dompurify'
import { isHTML } from '../../../helpers'

const PostCardStyled = styled(Card)`
  margin-bottom: ${spacing.md};

  &.updating {
    background: ${colors.black20};
  }
`

const CreatedBy = styled.div`
  padding: ${spacing.sm} ${spacing.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ProfileLink = styled.button`
  text-decoration: none;
  color: ${colors.joda};
  font-weight: bold;
  border: none;
  background: transparent;
`

const HtmlContent = styled.div`
  img {
    max-width: 100%;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }
`
type Props = {
  data: any
}

const getLikedByNames = (authUser: any, liked: any) =>
  liked.map((r: any) => (r.id === authUser!.id ? 'Du' : r.first_name))

export const PostCard: FunctionComponent<Props> = ({ data }) => {
  const intl = useIntl()
  const { authUser } = useContext(AuthContext)
  const history = useHistory()
  const { updatingPost, updatePostState, deletePost } = usePosts()

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [showAllText, setShowAllText] = useState<boolean>(false)

  const {
    id,
    owner,
    text,
    images,
    videos,
    need_moderation,
    created,
    created_by,
    cared,
    num_comments,
  } = data

  const [hasLiked, setHasLiked] = useState<boolean>(
    () => cared.findIndex((user: any) => user.id === authUser!.id) !== -1
  )

  const toggleLike = (id: string, hasLiked: boolean) => {
    if (hasLiked) {
      setHasLiked(false)

      authenticatedFetch(`${environment.API_URL}/posts/${id}/cared`, {
        method: 'DELETE',
      })
        .then((response) => updatePostState(response))
        .catch(() => setHasLiked(true)) // Reset on error
    } else {
      setHasLiked(true)

      authenticatedFetch(`${environment.API_URL}/posts/${id}/cared`, {
        method: 'POST',
      })
        .then((response) => updatePostState(response))
        .catch(() => setHasLiked(false)) // Reset on error
    }
  }

  if (need_moderation) {
    return <ModeratedContendCard />
  }

  const likedBy = getLikedByNames(authUser, cared)

  const getOwnerLink = () => {
    return owner.type === 'User'
      ? `/service-receivers/${owner.id}`
      : owner.type === 'Group'
      ? `/groups/${owner.id}`
      : `/institutions/${owner.id}`
  }

  function truncateMessage(text, n, useWordBoundary) {
    if (text.length <= n || showAllText) {
      return text
    }
    const subString = text.substr(0, n - 1) // the original check
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(' '))
        : subString) + '...'
    )
  }

  return (
    <React.Fragment>
      <PostCardStyled className={updatingPost === id ? 'updating' : ''}>
        <CardHeader>
          <ProfileLink
            onClick={() => history.push(getOwnerLink(), { title: owner.name })}
          >
            {owner.name}
          </ProfileLink>

          {authUser?.id === created_by.id && (
            <CardMenu
              onEdit={() => history.push(`/posts/${id}/edit`)}
              onDelete={() => {
                setShowConfirmDelete(true)
              }}
            />
          )}
        </CardHeader>

        <SwipeableMedia media={[...videos, ...images]} />
        {text && (
          <CardTextWithLoadMore>
            {isHTML(text) ? (
              <HtmlContent
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text),
                }}
              />
            ) : (
              <>
                {truncateMessage(text, 120, true)}
                {text.length > 120 && (
                  <Button
                    label={
                      !showAllText
                        ? intl.formatMessage(Common_ReadMore)
                        : intl.formatMessage(Common_ReadLess)
                    }
                    ariaLabel={
                      !showAllText
                        ? intl.formatMessage(Common_ReadMore)
                        : intl.formatMessage(Common_ReadLess)
                    }
                    variant="secondary"
                    onClick={() => setShowAllText(!showAllText)}
                    isXSmall
                    style={{
                      padding: `0  ${spacing.sm}`,
                      marginLeft: `${spacing.sm}`,
                    }}
                  />
                )}
              </>
            )}
          </CardTextWithLoadMore>
        )}
        <CreatedBy>
          <small>
            <small
              onClick={() => history.push(`/posts/${id}`)}
              style={{ cursor: 'pointer' }}
            >
              {new Date(created).toLocaleString()}{' '}
              {intl.formatMessage(Common_By)}
            </small>
            <ProfileLink
              onClick={() =>
                history.push(`/profile/${created_by.id}`, {
                  title: created_by.first_name,
                })
              }
            >
              {created_by.first_name}
            </ProfileLink>
          </small>

          <Avatar
            image={created_by.image}
            size="small"
            alt={`Bilde av ${created.first_name}`}
          />
        </CreatedBy>

        <CardActions>
          <CardAction
            color={hasLiked ? colors.closeLight : ''}
            onClick={() => toggleLike(id, hasLiked)}
          >
            <FontAwesomeIcon icon={faHeart} />
            {intl.formatMessage(Common_Heart)}
          </CardAction>

          <CardAction onClick={() => history.push(`/posts/${id}`)}>
            <FontAwesomeIcon icon={faComments} />
            {intl.formatMessage(Common_Comment)}
          </CardAction>

          <NotifyButton id={id} ownerId={owner.id} contentType="Post" />
        </CardActions>

        <CardMeta>
          {likedBy.length > 0 && (
            <Meta>
              <FontAwesomeIcon
                icon={faHeart}
                style={{ color: colors.closeLight, marginRight: spacing.sm }}
              />
              {likedBy.join(', ')}
            </Meta>
          )}

          {num_comments > 0 && (
            <Meta onClick={() => history.push(`/posts/${id}`)}>
              {intl.formatMessage(Common_NumComments, {
                numComments: num_comments,
              })}
            </Meta>
          )}
        </CardMeta>
      </PostCardStyled>

      <ConfirmDialog
        show={showConfirmDelete}
        title={Posts_Delete}
        confirmButtonText={Common_Delete}
        cancelButtonText={Common_Cancel}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={() => {
          setShowConfirmDelete(false)
          deletePost(data)
        }}
        onCancel={() => setShowConfirmDelete(false)}
      />
    </React.Fragment>
  )
}
