import React, { FunctionComponent, useState, useEffect } from 'react'
import { Dialog } from '../modal/Dialog'
import { PhotoProvider, PhotoSlider } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'

type Props = {
  onClose: () => void
  media: any[]
}

export const MediaDialog: FunctionComponent<Props> = ({ onClose, media }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }
  return (
    <Dialog onClose={handleClose}>
      <PhotoProvider>
        {isOpen && (
          <PhotoSlider
            images={media.map((x: any, index: number) => ({
              src: x.medium,
              key: index,
            }))}
            visible={isOpen}
            onClose={handleClose}
            index={selectedIndex}
            onIndexChange={setSelectedIndex}
          />
        )}
      </PhotoProvider>
    </Dialog>
  )
}
