import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  spacing,
} from '../../../styles/styles'
import { Card } from '../../shared/card/Card'
import { useIntl } from 'react-intl'
import {
  Common_Cancel,
  Common_ErrorOccurred,
  Common_Save,
  Common_Text,
  Messages_AccessWarning,
  Messages_HighPriority,
  Messages_Create,
} from '../../../translations/messages'
import { CheckBox } from '../../shared/form/CheckBox'
import { Button } from '../../shared/Buttons'
import { useHistory, useParams } from 'react-router-dom'
import { OwnerSelector } from '../../shared/owner-selector/OwnerSelector'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { ErrorMessage } from '../../shared/Messages'
import { MessageLoader } from '../../shared/Loading'
import { FadeIn } from '../../shared/animations/FadeIn'
import { FormButtons } from '../../shared/form/FormButtons'
import { NavBarContext } from '../../../contexts/NavBarContext'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Container = styled(PageContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.SMALL};
`

const Warning = styled.div`
  margin-bottom: ${spacing.md};
  border-radius: ${borderRadius};
  padding: ${spacing.md};
  background: ${colors.trustLight};
`

const QuillWrapper = styled.div`
  margin-bottom: ${spacing.md};

  .quill {
    .ql-container {
      min-height: 150px;
    }
  }
`

export const CreateMessagePage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { ownerId } = useParams<{ ownerId: string }>()

  const { updateNavBarTitle } = useContext(NavBarContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [bookStatus, setBookStatus] = useState<'idle' | 'pending' | 'failure'>(
    'idle'
  )
  const [message, setMessage] = useState<any>({ text: '', priority: 0 })
  const [owners, setOwners] = useState<any[]>([])

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Messages_Create))
  }, [updateNavBarTitle, intl])

  useEffect(() => {
    if (ownerId) {
      setBookStatus('pending')

      authenticatedFetch(
        `${environment.API_URL}/books/${ownerId}?contentType=Message`
      )
        .then((response) => {
          setBookStatus('idle')
          setOwners([response])
        })
        .catch(() => setBookStatus('failure'))
    }
  }, [ownerId, setOwners])

  const createMessage = () => {
    setStatus('pending')

    Promise.all(
      owners.map((owner) =>
        authenticatedFetch(
          `${environment.API_URL}/messages`,
          { method: 'POST' },
          { ...message, owner_id: owner.id }
        )
      )
    )
      .then(() => {
        setStatus('idle')
        history.goBack()
      })
      .catch(() => setStatus('failure'))
  }
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  }

  return (
    <Container>
      {status === 'pending' ? (
        <MessageLoader />
      ) : (
        <FadeIn showOn={true}>
          <Card style={{ padding: spacing.md }}>
            <OwnerSelector
              selected={owners}
              contentType="Message"
              disabled={bookStatus === 'pending'}
              onChange={(selected) => setOwners(selected)}
            />

            <Warning>{intl.formatMessage(Messages_AccessWarning)}</Warning>

            {status === 'failure' && (
              <ErrorMessage>
                {intl.formatMessage(Common_ErrorOccurred)}
              </ErrorMessage>
            )}
            <QuillWrapper>
              <ReactQuill
                theme="snow"
                value={message.text}
                onChange={(value) => setMessage({ ...message, text: value })}
                modules={modules}
                placeholder={intl.formatMessage(Common_Text)}
              />
            </QuillWrapper>
            <CheckBox
              label={Messages_HighPriority}
              name="priority"
              checked={message.priority === 1}
              onChange={(checked) =>
                setMessage({ ...message, priority: checked ? 1 : 0 })
              }
            />

            <FormButtons
              style={{ marginTop: spacing.md, justifyContent: 'flex-end' }}
            >
              <Button
                ariaLabel={intl.formatMessage(Common_Cancel)}
                label={intl.formatMessage(Common_Cancel)}
                variant="secondary"
                isSmall
                onClick={() => history.goBack()}
              />
              <Button
                ariaLabel={intl.formatMessage(Common_Save)}
                label={intl.formatMessage(Common_Save)}
                variant="primary"
                disabled={!message.text || owners.length === 0}
                isSmall
                onClick={createMessage}
              />
            </FormButtons>
          </Card>
        </FadeIn>
      )}
    </Container>
  )
}
