import React, { useEffect, useState } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import { environment } from '../../../environments'
import styled from 'styled-components'
import { breakpoints } from '../../../styles/styles'
import { authenticatedFetch } from '../../../lib/service'
import { MessageLoader } from '../../shared/Loading'
import { ModeratedListItem } from './ModeratedListItem'
import { ConfirmDialog } from '../../shared/modal/ConfirmDialog'
import { ErrorMessage } from '../../shared/Messages'
import { EmptyState } from '../../shared/EmptyState'
import {
  Common_Cancel,
  Common_Delete,
  Common_ErrorOccurred,
  Moderator_ConfirmApprove,
  Moderator_ConfirmConvertingToMessage,
  Moderator_ConfirmDelete,
  Moderator_Delete,
  Moderator_EmptyStateDescription,
  Moderator_EmptyStateTitle,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'

const ModeratorPageContainer = styled(PageContainer)`
  max-width: ${breakpoints.SMALL};
`

const ListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

type MediaToDelete = {
  id: string
  entityId: string
  type: 'image' | 'video'
}

export const ModeratorPage = () => {
  const intl = useIntl()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [entities, setEntities] = useState<any[] | null>(null)
  const [updatingEntity, setUpdatingEntity] = useState<string | null>(null)
  const [mediaToDelete, setMediaToDelete] = useState<MediaToDelete | null>(null)
  const [confirmApprove, setConfirmApprove] = useState<boolean>(false)
  const [confirmDisapprove, setConfirmDisapprove] = useState<boolean>(false)
  const [convertToMessageApproved, setConvertToMessageApproved] = useState<
    boolean
  >(false)
  const [loading, setLoading] = useState<boolean>(false)
  const deleteMedia = () => {
    setStatus('idle')

    const url =
      mediaToDelete!.type === 'image'
        ? `${environment.API_URL}/images/${mediaToDelete!.id}`
        : `${environment.API_URL}/videos/${mediaToDelete!.id}`

    const mediaId = mediaToDelete!.id
    const entityId = mediaToDelete!.entityId

    setMediaToDelete(null)
    setUpdatingEntity(entityId)

    authenticatedFetch(url, { method: 'DELETE' })
      .then(() => {
        setUpdatingEntity('')
        setEntities(
          entities!.map((e) => {
            if (e.id === entityId) {
              return {
                ...e,
                media: e.media.filter((m: any) => m.id !== mediaId),
              }
            }

            return e
          })
        )
      })
      .catch(() => {
        setUpdatingEntity('')
        setStatus('failure')
      })
  }

  const onApprove = (entityId: string) => {
    setUpdatingEntity(entityId)
    setConfirmApprove(true)
  }

  const approve = () => {
    setConfirmApprove(false)

    authenticatedFetch(`${environment.API_URL}/moderator/${updatingEntity}`, {
      method: 'PATCH',
    })
      .then(() => {
        setUpdatingEntity('')
        setEntities(entities!.filter((e) => e.entity.id !== updatingEntity))
      })
      .catch(() => {
        setStatus('failure')
        setUpdatingEntity('')
      })
  }

  const onDisapprove = (entityId: string) => {
    setUpdatingEntity(entityId)
    setConfirmDisapprove(true)
  }

  const disapprove = () => {
    setConfirmDisapprove(false)

    authenticatedFetch(`${environment.API_URL}/moderator/${updatingEntity}`, {
      method: 'DELETE',
    })
      .then(() => {
        setUpdatingEntity('')
        setEntities(entities!.filter((e) => e.entity.id !== updatingEntity))
      })
      .catch(() => {
        setStatus('failure')
        setUpdatingEntity('')
      })
  }

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/moderator`)
      .then((response) => {
        setStatus('idle')
        setEntities(response.results)
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [])
  const convertToMessage = () => {
    setLoading(true)
    setConvertToMessageApproved(false)
    const postToModerate = entities?.find((x) => x.entity.id === updatingEntity)
    if (!postToModerate) {
      setStatus('failure')
      setLoading(false)
      return
    }
    const messageTextWithCreatorData = `Denne meldingen ble laget av ${
      postToModerate.creator.first_name
    } ${postToModerate.creator.last_name} i boka på ${new Date(
      postToModerate.entity.created
    ).toLocaleString()} og flyttet fra boka til beskjeder.`

    authenticatedFetch(
      `${environment.API_URL}/messages`,
      { method: 'POST' },
      {
        text: `${messageTextWithCreatorData} ${postToModerate.entity.text}`,
        priority: 0,
        owner_id: postToModerate.owner.id,
        image_ids: postToModerate.media
          .filter((m) => !m.status)
          .map((m) => m.id),
        video_ids: postToModerate.media
          .filter((m) => m.status)
          .map((m) => m.id),
        created: postToModerate.entity.created,
      }
    )
      .then(() => {
        authenticatedFetch(
          `${environment.API_URL}/posts/${postToModerate.entity.id}`,
          {
            method: 'DELETE',
          }
        )
          .then(() => {
            setEntities(entities!.filter((e) => e.entity.id !== updatingEntity))
            setLoading(false)
          })
          .catch(() => setStatus('failure'))
        setUpdatingEntity('')
      })
      .catch(() => {
        setLoading(false)
        setStatus('failure')
      })
  }
  const onConvertToMessage = (entityId: string) => {
    setUpdatingEntity(entityId)
    setConvertToMessageApproved(true)
  }
  return (
    <React.Fragment>
      <ModeratorPageContainer>
        {status === 'failure' && (
          <ErrorMessage>
            {intl.formatMessage(Common_ErrorOccurred)}
          </ErrorMessage>
        )}

        {!entities || loading ? (
          <MessageLoader />
        ) : entities.length > 0 ? (
          <ListStyled>
            {entities.map((entity) => (
              <ModeratedListItem
                key={entity.id}
                disabled={entity.id === updatingEntity}
                data={entity}
                approve={onApprove}
                disapprove={onDisapprove}
                deleteMedia={(id, type) =>
                  setMediaToDelete({ id, type, entityId: entity.id })
                }
                convertToMessage={onConvertToMessage}
              />
            ))}
          </ListStyled>
        ) : (
          <EmptyState
            title={Moderator_EmptyStateTitle}
            text={Moderator_EmptyStateDescription}
          />
        )}
      </ModeratorPageContainer>

      <ConfirmDialog
        show={confirmApprove}
        title={Moderator_ConfirmApprove}
        onClose={() => {
          setConfirmApprove(false)
          setUpdatingEntity(null)
        }}
        onConfirm={approve}
        onCancel={() => {
          setConfirmApprove(false)
          setUpdatingEntity(null)
        }}
      />
      <ConfirmDialog
        show={convertToMessageApproved}
        title={Moderator_ConfirmConvertingToMessage}
        onClose={() => {
          setConvertToMessageApproved(false)
          setUpdatingEntity(null)
        }}
        onConfirm={convertToMessage}
        onCancel={() => {
          setConvertToMessageApproved(false)
          setUpdatingEntity(null)
        }}
      />
      <ConfirmDialog
        show={confirmDisapprove}
        title={Moderator_ConfirmDelete}
        onClose={() => {
          setConfirmDisapprove(false)
          setUpdatingEntity(null)
        }}
        onConfirm={disapprove}
        onCancel={() => {
          setConfirmDisapprove(false)
          setUpdatingEntity(null)
        }}
      />

      <ConfirmDialog
        show={Boolean(mediaToDelete)}
        title={Moderator_Delete}
        confirmButtonText={Common_Delete}
        cancelButtonText={Common_Cancel}
        onClose={() => setMediaToDelete(null)}
        onConfirm={deleteMedia}
        onCancel={() => setMediaToDelete(null)}
      />
    </React.Fragment>
  )
}
